<template>
  <article>
    <!-- Início principal -->
    <v-toolbar flat>
      <v-icon> fas fa-clock </v-icon>
      <v-toolbar-title class="pl-4"> Objetos Atrasados </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fechar">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <Data :title="$route.name" :dataInicio="camposDatas.dataInicial" :dataFinal="camposDatas.dataFinal" :indicadores="indicadores" />
    <!-- Fim principal -->
  </article>
</template>

<script>
import moment from "moment";
import Template from "../../components/Template.vue";
import Data from "./frmData.vue";

export default {
  name: "Atrasados",
  components: {
    Template,
    Data
  },
  data: () => ({
    indicadores: false,
    camposDatas: {
      dataInicial: moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'),
      dataFinal: moment(new Date()).format('YYYY-MM-DD'),
    },
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    if(this.$route.params.data_inicio != null)
      this.routeLoaded();
  },
  methods: {
    fechar() {
      this.$router.push("/");
    },
    routeLoaded(){
      let data_inicio = this.$route.params.data_inicio;
      let data_fim = this.$route.params.data_fim;
      this.indicadores = true;
      if(data_inicio == '' && data_fim == ''){
        data_inicio = moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD');
        data_fim = moment(new Date()).format('YYYY-MM-DD');
      }
      this.camposDatas.dataInicial = data_inicio
      this.camposDatas.dataFinal = data_fim;
    },
  },
  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
  },
};
</script>

<style scoped>
.active {
  background-color: #018656;
  color: #fff;
}
</style>
